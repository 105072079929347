export const GC_URL = "https://storage.googleapis.com/hunt-app-bucket";


if(!process.env.NODE_ENV){
    process.env.NODE_ENV = "development"
}
export let HOST =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : process.env.NODE_ENV === "stage"
        ? "https://dev.clggame.com"
        : "https://clggame.com";

/* export const HOST =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://clggame.com"; */
// export const PUBLIC_URL =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? `${HOST}:3000`
//     : `${HOST}`;
// export const SERVER_URL =
//   !process.env.NODE_ENV || process.env.NODE_ENV === "development"
//     ? `${HOST}:8080`
//     : `${HOST}`;

// export const HOST = "http://localhost";
//console.log("HOST", HOST, process.env.NODE_ENV, 'Window URL' , window.location.origin.includes("dev"));
if(process.env.NODE_ENV === "production" && window.location.origin.includes("dev")){
    HOST = "https://dev.clggame.com";
}
export const PUBLIC_URL = `${HOST}`;
export const SERVER_URL = `${HOST}`;


export const UPLOADS_API = "api/upload";
export const AUTH_API = "api/auth";
export const ADMIN_API = "api/admin";
export const GAME_API = "api/game";
