/* eslint-disable */

import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingBtn from "../mui/LoadingBtn";
import Tasks from "./Tasks";
import axios from "axios";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import BasicGrid from "../mui/Grid";
import DateTime from "../partials/DateTime";
import { useMediaQuery } from "react-responsive";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import BasicSelect from "../mui/BasicSelect";
import { SERVER_URL, UPLOADS_API } from "../../Config";
// import FileUpload from "./FileUpload";
import MultipleFiles from "./MultipleFiles";
import HuntTheme from "./HuntTheme";
import PlayerTypes from "./PlayerTypes";
import PlayerNames from "./PlayerNames";



export default function DetailsStage(props) {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    const [theme, setTheme] = useState({
        header: {
            bg: "#ffffff",
            color: "#000000",
        },
        body: {
            bg: "#ffffff",
            color: "#000000",
        },
        boxes: {
            bg: "#ffffff",
            color: "#000000",
        },
        footer: {
            bg: "#ffffff",
            color: "#000000",
        },
    });

    const [form, setForm] = useState({
        huntName: "",
        companyName: "",
        welcomeMessage: "",
        instructions: "",
        timing: true,
        hasEndDate: true,
        startDate: new Date(),
        endDate: new Date(),
        language: "English",
        maxParticipants: 5,
        visibleLeaderboard: true,
        showFeedOnEnd: false,
        allowReLogin: false,
        logoFileName: "",
        endingBannerName: "",
        backgroundFileName: "",
        logoLink: "",
        squareLogoLink: "",
        welcomeLink: "",
        endingLink: "",
        share_enabled: true,
    });

    const languages = [
        {
            name: "Hebrew",
            value: "Hebrew",
        },
        {
            name: "English",
            value: "English",
        },
    ];

    const [tasks, setTasks] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [logoFile, setLogoFile] = useState("");
    const [logoFileName, setLogoFilename] = useState("");
    const [premadeTeams, setPremadeTeams] = useState([]);

    const [logoSquareFile, setLogoSquareFile] = useState("");
    const [logoSquareFileName, setLogoSquareFilename] = useState("");

    const [endingBanner, setEndingBanner] = useState("");
    const [endingBannerName, setEndingBannerName] = useState("");

    const [welcomeBanner, setWelcomeBanner] = useState("");
    const [welcomeBannerName, setWelcomeBannerName] = useState("");

    const [backgroundImg, setBackgroundImg] = useState("");
    const [backgroundImgName, setBackgroundImgName] = useState("");

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [hasIndividuals, setHasIndividuals] = useState(true);
    const [hasTeams, setHasTeams] = useState(true);
    const [hasWatchers, setHasWatchers] = useState(true);

    const [individualName, setIndividualName] = useState("");
    const [teamsName, setTeamsName] = useState("");
    const [watcherName, setWatcherName] = useState("");

    const playerTypes = [
        {
            label: "Individuals",
            checked: hasIndividuals,
            onChange: () => {
                setHasIndividuals(!hasIndividuals);
            },
            name: "Ninja",
            setName: (e) => setIndividualName(e.target.value),
            value: individualName,
            placeholder: "שם לשחקן יחיד",
        },
        {
            label: "Teams",
            checked: hasTeams,
            onChange: () => setHasTeams(!hasTeams),
            name: "Hunters",
            setName: (e) => setTeamsName(e.target.value),
            value: teamsName,
            placeholder: "שם לצוותים",
        },
        {
            label: "Watchers",
            checked: hasWatchers,
            onChange: () => setHasWatchers(!hasWatchers),
            name: "Watcher",
            setName: (e) => setWatcherName(e.target.value),
            value: watcherName,
        },
    ];

    const onUploadFile = (e) => {
        let _file = files.find((f) => f.id === e.target.id);
        _file.setFile(e.target.files[0]);
        _file.setFilename(e.target.files[0].name);
    };

    const onRemoveFile = (e) => {
        let _file = files.find((f) => `${f.id}_${f.id}` === e.target.id);
        _file.setFile("");
        _file.setFilename("");
    };

    const files = [
        {
            id: "1",
            file: logoFile,
            filename: logoFileName,
            setFile: (e) => setLogoFile(e),
            setFilename: (e) => setLogoFilename(e),
        },
        {
            id: "1_2",
            file: logoSquareFile,
            filename: logoSquareFileName,
            setFile: (e) => setLogoSquareFile(e),
            setFilename: (e) => setLogoSquareFilename(e),
        },
        {
            id: "1_1",
            file: welcomeBanner,
            filename: welcomeBannerName,
            setFile: (e) => setWelcomeBanner(e),
            setFilename: (e) => setWelcomeBannerName(e),
        },
        {
            id: "2",
            file: endingBanner,
            filename: endingBannerName,
            setFile: (e) => setEndingBanner(e),
            setFilename: (e) => setEndingBannerName(e),
        },
        {
            id: "3",
            file: backgroundImg,
            filename: backgroundImgName,
            setFile: (e) => setBackgroundImg(e),
            setFilename: (e) => setBackgroundImgName(e),
        },
    ];

    const handleThemeChange = (event, part, fieldName) => {
        setTheme({
            ...theme,
            [part]: { ...theme[part], [fieldName]: event.target.value },
        });
    };

    const handleChange = (event, fieldName) => {
        setForm({ ...form, [fieldName]: event.target.value });
    };

    const handleCheck = (fieldName, value) => {
        setForm({ ...form, [fieldName]: value });
    };

    const handleSelect = (e, dateType) => {
        setForm({ ...form, [dateType]: new Date(e) });
    };

    const themeOptions = [
        {
            name: "header",
        },
        {
            name: "body",
        },
        {
            name: "boxes",
        },
        {
            name: "footer",
        },
    ];

    const submitForm = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        const formData = new FormData();
        formData.append("huntName", form.huntName);
        formData.append("companyName", form.companyName);
        formData.append("theme", JSON.stringify(theme));
        formData.append("welcomeMessage", form.welcomeMessage);
        formData.append("instructions", form.instructions);
        formData.append("timing", form.timing);
        formData.append("hasEndDate", form.hasEndDate);
        formData.append("startDate", form.startDate);
        formData.append("endDate", form.endDate);
        formData.append(
            "timeZone",
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        formData.append("language", form.language);
        formData.append("maxParticipants", form.maxParticipants);
        formData.append("premade_teams", JSON.stringify(premadeTeams));
        formData.append("has_individuals", hasIndividuals);
        formData.append("has_teams", hasTeams);
        formData.append("share_enabled", form.share_enabled);
        formData.append("has_watchers", hasWatchers);
        formData.append("individual_name", individualName);
        formData.append("teams_name", teamsName);
        formData.append("watcher_name", watcherName);
        formData.append("visibleLeaderboard", form.visibleLeaderboard);
        formData.append("showFeedOnEnd", form.showFeedOnEnd ?? false);
        formData.append("allowReLogin", form.allowReLogin ?? false);
        formData.append("logoFileName", logoFileName);
        formData.append("logoSquareFileName", logoSquareFileName);
        formData.append("endingBannerName", endingBannerName);
        formData.append("welcome_banner", welcomeBannerName);
        formData.append("bg_img", backgroundImgName);
        formData.append("logo_link", form.logoLink);
        formData.append("square_logo_link", form.squareLogoLink);
        formData.append("welcome_link", form.welcomeLink);
        formData.append("ending_link", form.endingLink);

        formData.append("tasks", JSON.stringify(tasks));
        let images = [
            logoFile,
            logoSquareFile,
            endingBanner,
            welcomeBanner,
            backgroundImg,
        ];

        tasks.forEach((task) => {
            formData.append("taskImages", task.file);
        });

        images.forEach((file) => {
            formData.append("images", file);
        });

        await axios
            .post(`${SERVER_URL}/${UPLOADS_API}/new-hunt`, formData, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "auth-token": props.user.token,
                },
            })
            .then((res) => {
                setTimeout(() => {
                    setSuccess(`${form.huntName} has been created!`);
                }, 3000);
                window.location.reload();
            })
            .catch((err) => {
                if (err.response.message) {
                    setError(err.response.message);
                }
            });
        setSubmitLoading(false);
    };

    const formComponents = [
        {
            id: 1,
            xs: 6,
            component: (
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 1, width: "90%" },
                    }}
                    autoComplete="off"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h4 style={{ textAlign: "left" }}>Basic Information:</h4>
                    <TextField
                        id="outlined-name"
                        label="Hunt Name"
                        onChange={(e) => handleChange(e, "huntName")}
                        placeholder="Amazing Race"
                        value={form.huntName}
                        required
                        fullWidth
                    />
                    <br />
                    <TextField
                        id="outlined-name"
                        label="Company Name"
                        onChange={(e) => handleChange(e, "companyName")}
                        placeholder="Google"
                        value={form.companyName}
                        required
                    />
                    {/* <br />
          <TextareaAutosize
            aria-label="Welcome Message"
            minRows={5}
            maxRows={6}
            placeholder="Welcome Message"
            style={{
              width: "90%",
              maxWidth: "90%",
              padding: "10px",
              outline: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",
            }}
            onChange={(e) => handleChange(e, "welcomeMessage")}
            value={form.welcomeMessage}
          /> */}
                    <br />
                    <TextareaAutosize
                        aria-label="Hunt Instructions"
                        minRows={5}
                        maxRows={6}
                        placeholder="Hunt Instructions"
                        style={{
                            width: "90%",
                            maxWidth: "90%",
                            padding: "10px",
                            outline: "none",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                        }}
                        onChange={(e) => handleChange(e, "instructions")}
                        value={form.instructions}
                    />
                    <br />
                    <TextField
                        id="outlined-name"
                        label="Wide Logo Link"
                        onChange={(e) => handleChange(e, "logoLink")}
                        placeholder="Logo Link"
                        value={form.logoLink}
                        fullWidth
                    />
                    <br />
                    <TextField
                        id="outlined-name"
                        label="Square Logo Link"
                        onChange={(e) => handleChange(e, "squareLogoLink")}
                        placeholder="Square Logo Link"
                        value={form.squareLogoLink}
                        fullWidth
                    />
                    <br />
                    <TextField
                        id="outlined-name"
                        label="Welcome Banner Link"
                        onChange={(e) => handleChange(e, "welcomeLink")}
                        placeholder="Welcome Banner Link"
                        value={form.welcomeLink}
                        fullWidth
                    />
                    <br />
                    <TextField
                        id="outlined-name"
                        label="Ending Banner Link"
                        onChange={(e) => handleChange(e, "endingLink")}
                        placeholder="Ending Banner Link"
                        value={form.endingLink}
                        fullWidth
                    />
                    <br />
                    <FormGroup>
                        <FormControlLabel
                            style={{
                                justifyContent: "start",
                                backgroundColor: "#f5efcd",
                                borderRadius: "20px",
                                width: isTabletOrMobile ? "70%" : "50%",
                                margin: "5px",
                            }}
                            control={
                                <Checkbox
                                    checked={form.share_enabled}
                                    onChange={() => {
                                        handleCheck("share_enabled", !form.share_enabled);
                                    }}
                                />
                            }
                            label="Share Enabled?"
                        />
                    </FormGroup>
                </Box>
            ),
        },
        {
            id: 2,
            xs: 6,
            component: (
                <Box
                    style={{
                        textAlign: "left",
                    }}
                    sx={{
                        "& > :not(style)": { m: 1, width: "90%" },
                    }}
                >
                    <h4 style={{ textAlign: "left" }}>Hunt Branding:</h4>
                    <div
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90%",
                            backgroundColor: "#F8C937",
                            padding: "15px",
                            borderRadius: "10px",
                        }}
                    >
                        <HuntTheme
                            options={themeOptions}
                            theme={theme}
                            handleChange={(e, part, field) =>
                                handleThemeChange(e, part, field)
                            }
                        />
                    </div>

                    <br />
                    <BasicSelect
                        options={languages}
                        label={form.language}
                        style={{ width: "90%" }}
                        onChange={(e) => handleChange(e, "language")}
                        value={form.language}
                    />
                    <br />
                    <MultipleFiles
                        onRemoveFile={onRemoveFile}
                        files={files}
                        onChange={onUploadFile}
                    />
                    <br />
                </Box>
            ),
        },
        {
            id: 3,
            xs: 6,
            component: (
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 1, width: "90%" },
                    }}
                    autoComplete="off"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h4 style={{ textAlign: "left" }}>Hunt Timing:</h4>
                    <FormGroup>
                        <FormControlLabel
                            style={{
                                justifyContent: "start",
                                backgroundColor: "#f5efcd",
                                borderRadius: "20px",
                                width: isTabletOrMobile ? "70%" : "50%",
                                margin: "5px",
                            }}
                            control={
                                <Checkbox
                                    checked={form.timing}
                                    onChange={() => {
                                        handleCheck("timing", !form.timing);
                                    }}
                                />
                            }
                            label="Start Date"
                        />
                    </FormGroup>
                    <>
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    justifyContent: "start",
                                    backgroundColor: "#f5efcd",
                                    borderRadius: "20px",
                                    width: isTabletOrMobile ? "70%" : "50%",
                                    margin: "5px",
                                }}
                                control={
                                    <Checkbox
                                        checked={form.hasEndDate}
                                        onChange={() => {
                                            handleCheck("hasEndDate", !form.hasEndDate);
                                        }}
                                    />
                                }
                                label="End Date"
                            />
                        </FormGroup>
                        {form.timing && (
                            <>
                                {" "}
                                <h4 style={{ textAlign: "left" }}>Start Date:</h4>
                                <DateTime
                                    onChange={(e) => handleSelect(e, "startDate")}
                                    value={form.startDate}
                                />
                            </>
                        )}
                        {form.hasEndDate && (
                            <>
                                <h4 style={{ textAlign: "left" }}>End Date:</h4>
                                <DateTime
                                    onChange={(e) => handleSelect(e, "endDate")}
                                    value={form.endDate}
                                />
                            </>
                        )}
                    </>

                </Box>
            ),
        },
        {
            id: 4,
            xs: 6,
            component: (
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 1, width: "90%" },
                    }}
                    autoComplete="off"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h4 style={{ textAlign: "left" }}>Participants:</h4>
                    <TextField
                        type="number"
                        id="outlined-number"
                        label="Max Participants Per Team"
                        onChange={(e) => handleChange(e, "maxParticipants")}
                        placeholder="Max Participants Per Team"
                        value={form.maxParticipants}
                        required
                        fullWidth
                    />
                    <TextField
                        type="text"
                        id="outlined-text2"
                        label="Pre-made Teams (Comma Separated)"
                        onChange={(e) => {
                            setPremadeTeams(e.target.value.split(","));
                        }}
                        placeholder="Pre-made Teams"
                        value={premadeTeams.join(",")}
                        required
                        fullWidth
                    />
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                        <FormGroup style={{ flexGrow: 1, }}>
                            <FormControlLabel
                                style={{
                                    justifyContent: "start",
                                    backgroundColor: "#f5efcd",
                                    borderRadius: "20px",
                                    width: isTabletOrMobile ? "80%" : "100%",
                                    margin: "5px",
                                }}
                                control={
                                    <Checkbox
                                        checked={form.visibleLeaderboard}
                                        onChange={(e) => {
                                            handleCheck("visibleLeaderboard", !form.visibleLeaderboard);
                                        }}
                                    />
                                }
                                label="Visible Leaderboard"
                            />
                        </FormGroup>
                        <FormGroup style={{ flexGrow: 1, }}>
                            <FormControlLabel
                                style={{
                                    justifyContent: "start",
                                    backgroundColor: "#f5efcd",
                                    borderRadius: "20px",
                                    width: isTabletOrMobile ? "80%" : "100%",
                                    margin: "5px",
                                    flexGrow: 1,
                                }}
                                control={
                                    <Checkbox
                                        checked={form.showFeedOnEnd}
                                        onChange={(e) => {
                                            handleCheck("showFeedOnEnd", !form.showFeedOnEnd);
                                        }}
                                    />
                                }
                                label="Show Feed on End"
                            />

                        </FormGroup>
                        <FormGroup style={{ flexGrow: 1, }}>
                            <FormControlLabel
                                style={{
                                    justifyContent: "start",
                                    backgroundColor: "#f5efcd",
                                    borderRadius: "20px",
                                    width: isTabletOrMobile ? "80%" : "100%",
                                    margin: "5px",
                                    flexGrow: 1,
                                }}
                                control={
                                    <Checkbox
                                        checked={form.allowReLogin}
                                        onChange={(e) => {
                                            handleCheck("allowReLogin", !form.allowReLogin);
                                        }}
                                    />
                                }
                                label="Allow Re-Login"
                            />

                        </FormGroup>
                    </div>

                    <PlayerTypes
                        options={playerTypes}
                        isTabletOrMobile={isTabletOrMobile}
                    />
                    <PlayerNames
                        options={playerTypes}
                        isTabletOrMobile={isTabletOrMobile}
                    />
                </Box>
            ),
        },
        {
            id: 5,
            xs: 12,
            component: (
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 1, width: "100%", paddingLeft: "0px" },
                    }}
                    autoComplete="off"
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h1 style={{ textAlign: "center" }}>Hunt Tasks:</h1>
                    <hr style={{ margin: "0px" }} />
                    <br />
                    <Tasks tasks={tasks} setTasks={setTasks} user={props.user} />
                </Box>
            ),
        },
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <BasicGrid data={formComponents} />
            </div>
            <div style={{ textAlign: "center" }}>
                <LoadingBtn
                    style={{
                        width: isTabletOrMobile ? "50%" : "20%",
                        margin: "10px auto",
                        backgroundColor: "#fabe44",
                        color: "black",
                        borderColor: "#fabe44",
                    }}
                    txt="Create Hunt"
                    loading={submitLoading}
                    onClick={submitForm}
                    type="submit"
                />
                {error && <span className="error">{error}</span>}
                {success && <span className="success">{success}</span>}
            </div>
        </div>
    );
}
