/* eslint-disable */

import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import LoadingBtn from "./LoadingBtn";
import Slide from "@mui/material/Slide";
import UploadFile from "../client/UploadFile";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConfettiExplosion from "react-confetti-explosion";

import { ImCross, ImCheckmark } from "react-icons/im";
import { MdOutlinePendingActions } from "react-icons/md";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TaskDialog(props) {
  useEffect(() => {
    const langAttr = props.lang.substr(0, 2).toLowerCase();
    const divs = document.querySelectorAll("div");

    divs.forEach((div, index) => {
      div.setAttribute("lang", langAttr);
    });
  }, []);

  const localization = [
    {
      name: "rightAnswerPrefix",
      Hebrew: "התשובה הנכונה היא: ",
      English: "The correct answer is: ",
    },
    {
      name: "rightOptionPrefix",
      Hebrew: "האפשרות הנכונה היא: ",
      English: "The correct option is: ",
    },
  ];

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        style={{
          position: "fixed",
          top: "0px",
          height: "92%",
        }}
        lang={props.lang.substr(0, 2).toLowerCase()}
      >
        {/* <div
          style={{
            position: "relative",
            backgroundColor: "transparent",
            // backgroundColor: props.hunt.hunt_theme.header.bg,
            // color: props.hunt.hunt_theme.header.color,
            fontWeight: "bold",
            textAlign: "center",
          }}
          lang={props.lang.substr(0, 2).toLowerCase()}
        >
          <Toolbar lang={props.lang.substr(0, 2).toLowerCase()}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{ fontSize: "24px" }} />
            </IconButton>
            <Typography
              lang={props.lang.substr(0, 2).toLowerCase()}
              sx={{
                ml: props.lang === "Hebrew" ? 2 : 0,
                mr: props.lang === "Hebrew" ? 0 : 2,
                flex: 1,
                fontWeight: "bold",
                padding: "8px",
              }}
              variant="h6"
              component="div"
            >
              {props.title}
            </Typography>
          </Toolbar>
        </div> */}
        <DialogContent
          lang={props.lang.substr(0, 2).toLowerCase()}
          style={{
            // background: `url(${GC_URL}/${props.hunt.bg_img})`,
            backgroundColor: props.hunt.hunt_theme.boxes.bg,
            color: props.hunt.hunt_theme.boxes.color,
            padding: "0px",
            textAlign: "center",
          }}
        >
          <div
            style={{ textAlign: props.lang === "Hebrew" ? "right" : "left" }}
          >
            <IconButton
              style={{
                position: "relative",
                right: props.lang === "Hebrew" ? "20px" : "0px",
                left: props.lang === "Hebrew" ? "0px" : "20px",
                top: "10px",
                backgroundColor: props.hunt.hunt_theme.boxes.bg,
                color: props.hunt.hunt_theme.boxes.color,
              }}
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          </div>
          <div style={{ marginTop: "-40px" }}>
            {!props.submitted && props.component}
            <br />
            {!props.submitted ? (
              <DialogActions
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  // backgroundColor: "transparent",
                  // backgroundColor: props.hunt.hunt_theme.footer.bg,
                  color: props.hunt.hunt_theme.footer.color,
                  minHeight: "4rem",
                  width: "90%",
                  margin: "auto",
                }}
              >
                {(props.item.type === "photo" || props.item.type === "video") &&
                ((props.item.type === "photo" && !props.cropped) ||
                  (props.item.type === "video" && !props.selectedFile)) ? (
                  <UploadFile
                    hunt={props.hunt}
                    submitForm={props.submitForm}
                    selectedFile={props.selectedFile}
                    preview={props.preview}
                    onSelectFile={props.onSelectFile}
                    label={props.label}
                    type={props.item.type}
                  />
                ) : (
                  <LoadingBtn
                    lang={props.lang}
                    loading={props.loading}
                    style={props.style}
                    onClick={props.onClick}
                    txt={props.txt}
                    customIcon={
                      props.lang === "Hebrew" && <FaArrowAltCircleLeft />
                    }
                  />
                )}
              </DialogActions>
            ) : props.submitted && props.submitted.autoScore ? (
              <>
                {props.submitted.type === "multi" ? (
                  props.submitted.correct_option === Number(props.answer) ? (
                    <div className="inline-center" style={{ height: "80vh" }}>
                      <ImCheckmark
                        size={30}
                        style={{
                          fill: props.hunt.hunt_theme.boxes.color,
                          margin: "0px 4px",
                        }}
                      />
                      <p
                        style={{
                          color: props.hunt.hunt_theme.boxes.color,
                          fontSize: "32px",
                          textAlign: "center",
                        }}
                      >
                        {props.rightText}
                      </p>
                      <ConfettiExplosion
                        force={0.6}
                        duration={5000}
                        particleCount={200}
                        height={1600}
                        width={1600}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="inline-center" style={{ height: "80vh" }}>
                        <ImCross
                          size={30}
                          style={{
                            fill: props.hunt.hunt_theme.boxes.color,
                            margin: "0px 4px",
                          }}
                        />
                        <div>
                          <p
                            className="error"
                            style={{
                              textAlign: "center",
                              fontSize: "32px",
                              color: props.hunt.hunt_theme.boxes.color,
                            }}
                          >
                            {props.wrongText}
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          margin: "0px",
                          textAlign: "center",
                          fontSize: "22px",
                          color: props.hunt.hunt_theme.boxes.color,
                        }}
                      >
                        {
                          localization.find(
                            (s) => s.name === "rightOptionPrefix"
                          )[props.lang]
                        }{" "}
                        {props.submitted.correct_option} - {props.submitted.options.find((option) => option.index === props.submitted.correct_option).value}
                      </p>
                    </>
                  )
                ) : props.submitted.type === "qa" ? (
                  props.submitted.answer === props.answer ? (
                    <div className="inline-center" style={{ height: "80vh" }}>
                      <ImCheckmark
                        size={30}
                        style={{
                          fill: props.hunt.hunt_theme.boxes.color,
                          margin: "0px 4px",
                        }}
                      />
                      <p
                        style={{
                          color: props.hunt.hunt_theme.boxes.color,
                          textAlign: "center",
                          fontSize: "32px",
                        }}
                      >
                        {props.rightText}
                      </p>
                      <ConfettiExplosion
                        force={0.6}
                        duration={5000}
                        particleCount={200}
                        height={1600}
                        width={1600}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="inline-center" style={{ height: "80vh" }}>
                        <ImCross
                          size={30}
                          style={{
                            fill: props.hunt.hunt_theme.boxes.color,
                            margin: "0px 4px",
                          }}
                        />
                        <p
                          className="error"
                          style={{
                            textAlign: "center",
                            fontSize: "32px",
                            color: props.hunt.hunt_theme.boxes.color,
                          }}
                        >
                          {props.wrongText}
                        </p>
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "22px",
                          color: props.hunt.hunt_theme.boxes.color,
                        }}
                      >
                        {
                          localization.find(
                            (s) => s.name === "rightAnswerPrefix"
                          )[props.lang]
                        }{" "}
                        {props.submitted.answer}
                      </p>
                    </>
                  )
                ) : (
                  <div className="inline-center" style={{ height: "80vh" }}>
                    <MdOutlinePendingActions
                      style={{
                        fill: props.hunt.hunt_theme.boxes.color,
                        margin: "0px 4px",
                      }}
                      size={30}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        color: props.hunt.hunt_theme.boxes.color,
                        fontSize: "32px",
                      }}
                    >
                      {props.sentText}
                    </p>
                  </div>
                )}
              </>
            ) : (
              props.submitted && (
                <div className="inline-center" style={{ height: "80vh" }}>
                  <MdOutlinePendingActions
                    style={{
                      fill: props.hunt.hunt_theme.boxes.color,
                      margin: "0px 4px",
                    }}
                    size={30}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: props.hunt.hunt_theme.boxes.color,
                      fontSize: "32px",
                    }}
                  >
                    {props.sentText}
                  </p>
                </div>
              )
            )}
          </div>
        </DialogContent>
        {props.submitError && (
          <p className="error" style={{ textAlign: "center" }}>
            {props.submitError}
          </p>
        )}
        {/* {props.submitted && props.submitted.autoScore ? (
          <>
            {props.submitted.type === "multi" ? (
              props.submitted.correct_option === Number(props.answer) ? (
                <p style={{ color: "green", textAlign: "center" }}>
                  {props.rightText}
                </p>
              ) : (
                <p className="error" style={{ textAlign: "center" }}>
                  {props.wrongText}
                </p>
              )
            ) : props.submitted.answer === props.answer ? (
              <p style={{ color: "green", textAlign: "center" }}>
                {props.rightText}
              </p>
            ) : (
              <p className="error" style={{ textAlign: "center" }}>
                {props.wrongText}
              </p>
            )}
          </>
        ) : (
          props.submitted &&
          !props.submitted.autoScore && (
            <p style={{ textAlign: "center" }}>{props.sentText}</p>
          )
        )} */}

        {/* <DialogActions
          style={{
            display: "inline-flex",
            justifyContent: "center",
            // backgroundColor: "transparent",
            // backgroundColor: props.hunt.hunt_theme.footer.bg,
            color: props.hunt.hunt_theme.footer.color,
            minHeight: "4rem",
          }}
        >
          {(props.item.type === "photo" || props.item.type === "video") &&
          !props.preview ? (
            <UploadFile
              submitForm={props.submitForm}
              selectedFile={props.selectedFile}
              preview={props.preview}
              onSelectFile={props.onSelectFile}
              label={props.label}
            />
          ) : (
            <LoadingBtn
              lang={props.lang}
              loading={props.loading}
              style={props.style}
              onClick={props.onClick}
              txt={props.txt}
              customIcon={props.lang === "Hebrew" && <FaArrowAltCircleLeft />}
            />
          )}
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
