/* eslint-disable */

import { useEffect } from "react";
import { GC_URL } from "../../Config";
import PlayerType from "./PlayerType";

export default function Welcome(props) {
  const localization = [
    {
      name: "Inactive",
      English: "Hunt Is Inactive",
      Hebrew: "אין אפשרות לשחק במשחק זה",
    },
    {
      name: "Completed",
      English: "Hunt has already been completed",
      Hebrew: "המשחק הושלם",
    },
  ];

  return (
    <div
      style={{
        background: `url(${GC_URL}/${props.hunt.bg_img.replace(/ /g, "%20")})`,
        backgroundColor: props.hunt.hunt_theme.body.bg,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxHeight: "100vh",
        height: "100vh",
        overflowY: "scroll",
        padding: "1.5rem 2rem",
        // borderRadius: "20px",
        textAlign: "center",
      }}
    >
      <h2 style={{ marginTop: "0px", color: props.hunt.hunt_theme.body.color }}>
        {props.hunt.hunt_name}
      </h2>
      <div>
        <PlayerType
          setLoginError={props.setLoginError}
          loginError={props.loginError}
          lang={props.lang}
          isTabletOrMobile={props.isTabletOrMobile}
          hunt={props.hunt}
          teamOptions={props.teamOptions}
          enterGame={(e, type, playerName) =>
            props.enterGame(e, type, playerName)
          }
          timeToPlay={props.hunt.limit_duration_per_user}
          submitLoading={props.submitLoading}
        />
      </div>
      {/* {props.status != "Active" ? (
        <>
          <h4 style={{ color: props.hunt.hunt_theme.body.color }}>
            {localization.find((l) => l.name === props.status)[props.lang]}
          </h4>
          {props.status === "Completed" && (
            <img
              src={`${GC_URL}/${props.hunt.ending_banner}`}
              style={{ width: "300px", borderRadius: "10px" }}
            />
          )}
        </>
      ) : (
        <div>
          <PlayerType
            setLoginError={props.setLoginError}
            loginError={props.loginError}
            lang={props.lang}
            isTabletOrMobile={props.isTabletOrMobile}
            hunt={props.hunt}
            teamOptions={props.teamOptions}
            enterGame={(e, type, playerName) =>
              props.enterGame(e, type, playerName)
            }
            submitLoading={props.submitLoading}
          />
        </div>
      )} */}
    </div>
  );
}
