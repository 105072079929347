/* eslint-disable */

import TextField from "@mui/material/TextField";
import RowRadioButtonsGroup from "./CorrectOption";

export default function MultipleChoice(props) {
  const {options, onAddOptionClick, removeOption } = props;

  const optionsCount = options.length;

  return (
    <div>
        {options?.map((option, i) =>(
        <div className="add-task-container">
          <TextField
            className="add-task-field"
            key={option.index}
            index={option.index}
            margin="dense"
            id={`Option ${options[i].index}_${i}`}
            label={`Option ${options[i].index}`}
            type="text"
            fullWidth
            variant="filled"
            onChange={(event) => props.updateOption(option.index, event.target.value)}
            value={option.value}
          />
          {option.index > 1 && <div
            onClick={(e) => {
              e.stopPropagation();
              removeOption(option.index);
            }}
            className="pointer remove-option-button"
          >
            X
          </div>
          }
        </div>
      ))}
      <button onClick={onAddOptionClick} className="add-option-button">add option</button>
      <br />
      <br />
      <RowRadioButtonsGroup value={props.correct} onChange={props.onChange} optionsCount={optionsCount}/>
    </div>
  );
}
