/* eslint-disable */

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  FaCamera,
  FaVideo,
  FaQuestionCircle,
  FaList,
  FaStar,
  FaInfoCircle,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import LoaderIcon from "react-loader-icon";
import { MdLocationOn } from "react-icons/md";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GC_URL } from "../../Config";

export default function DraggableList(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  //   const defaultList = ["A", "B", "C", "D", "E"];
  // React state to track order of items
  //   const [itemList, setItemList] = useState(props.tasks);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...props.tasks];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    // setItemList(updatedList);
    props.setTasks(updatedList);
  };

  const customIcons = ["location", "bonus"];

  const icons = [
    {
      type: "photo",
      icon: <FaCamera size={25} />,
    },
    {
      type: "video",
      icon: <FaVideo size={25} />,
    },
    {
      type: "qa",
      icon: <FaQuestionCircle size={25} />,
    },
    {
      type: "multi",
      icon: <FaList size={25} />,
    },
    {
      type: "location",
      icon: <MdLocationOn size={25} />,
    },
    {
      type: "bonus",
      icon: <FaStar size={25} />,
    },
    {
      type: "information",
      icon: <FaInfoCircle size={25} />,
    },
  ];

  return (
    <div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.tasks.map((item, index) => (
                <Draggable
                  key={`${item.name}${index}`}
                  draggableId={`${item.name}${index}`}
                  index={index}
                  id={item.name}
                >
                  {(provided) => (
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid black",
                        padding: "25px",
                        margin: "15px 50px",
                      }}
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <img
                        src={`${GC_URL}/${item.filename}`}
                        style={{
                          width: "100px",
                          // height: "80px",
                          objectFit: "cover",
                          borderRadius: "0px 50px 50px 0px",
                          position: "relative", // Change position to relative
                          left: "-15px",
                        }}
                      />

                      {props.editTask && (
                        <div
                          style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <Checkbox
                            color="success"
                            onChange={() => {
                              props.selectTasks(item._id);
                            }}
                          />
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.editTask && props.editTask(item)
                            }
                          />
                          <ContentCopyIcon
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            onClick={() =>
                              props.editTask && props.cloneTask(item)
                            }
                          />
                        </div>
                      )}

                      <div
                        style={{
                          backgroundColor: "black",
                          borderRadius: "50px",
                          color: "white",
                          width: isTabletOrMobile ? "30px" : "40px",
                          height: isTabletOrMobile ? "30px" : "40px",
                          padding: "10px",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {customIcons.includes(item.icon)
                          ? icons.find((i) => i.type === item.icon).icon
                          : icons.find((i) => i.type === item.type).icon}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {item.category ? item.category : "NONE"}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {item.title ? item.title : item.name.substring(0, 50)}
                      </div>
                      {!isTabletOrMobile && (
                        <div style={{ textAlign: "center" }}>
                          {
                            props.taskOptions.find((i) => i.value === item.type)
                              .name
                          }
                        </div>
                      )}
                      <div style={{ textAlign: "center" }}>
                        {item.autoScore ? "Auto Scoring" : "Manual Scoring"}
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FABE44",
                          borderRadius: "10px",
                          padding: "5px",
                          textAlign: "center",
                          color: "black",
                          width: isTabletOrMobile ? "30px" : "40px",
                        }}
                      >
                        <span>{item.score}</span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "red",
                          borderRadius: "50px",
                          padding: "5px",
                          textAlign: "center",
                          color: "white",
                          width: isTabletOrMobile ? "25px" : "40px",
                          height: isTabletOrMobile ? "25px" : "40px",
                          margin: isTabletOrMobile && "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.removeTask(item);
                        }}
                        className="pointer"
                      >
                        {props.removeLoading === item._id ? (
                          <LoaderIcon
                            type={"spin"}
                            style={{
                              width: "20px",
                              height: "20px",
                              fill: "white",
                            }}
                          />
                        ) : (
                          "X"
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {props.loadBtn && props.loadBtn}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
