/* eslint-disable */

import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

export default function MultiTextField(props) {
  const theme = createTheme({
    direction: props.lang === "Hebrew" ? "rtl" : "ltr",
  });
  // Create rtl cache
  const cacheRtl = createCache({
    key: props.lang === "Hebrew" ? "muirtl" : "muiltr",
    stylisPlugins: props.lang === "Hebrew" && [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <TextField
          dir={props.lang === "Hebrew" ? "rtl" : "ltr"}
          type="text"
          id="outlined-text"
          label={props.label}
          onChange={props.onChange}
          placeholder={props.placeholder}
          value={props.value}
          required
          fullWidth
          InputProps={{
            style: { color: props.textColor || "inherit" }, // Apply custom color if provided
          }}
          InputLabelProps={{
            style: { color: props.textColor || "inherit" }, // Apply custom label color if provided
          }}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}
