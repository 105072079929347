/* eslint-disable */

import TextField from "@mui/material/TextField";
import MultipleFiles from "./MultipleFiles";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMediaQuery } from "react-responsive";
import MultipleChoice from "./MultipleChoice";
import BasicSelect from "../mui/BasicSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function TaskForm(props) {
  const mediaTasks = ["photo", "video"];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const links = [
    {
      textId: "linkText1",
      urlId: "link1",
      onTextChange: (e) => props.onLinkTxtChange(e, "text1"),
      onUrlChange: (e) => props.onUrlChange(e, "url1"),
      textValue: props.linkTxtValues.text1,
      urlValue: props.linkUrlValues.url1,
    },
    {
      textId: "linkText2",
      urlId: "link2",
      onTextChange: (e) => props.onLinkTxtChange(e, "text2"),
      onUrlChange: (e) => props.onUrlChange(e, "url2"),
      textValue: props.linkTxtValues.text2,
      urlValue: props.linkUrlValues.url2,
    },
    {
      textId: "linkText3",
      urlId: "link3",
      onTextChange: (e) => props.onLinkTxtChange(e, "text3"),
      onUrlChange: (e) => props.onUrlChange(e, "url3"),
      textValue: props.linkTxtValues.text3,
      urlValue: props.linkUrlValues.url3,
    },
  ];


  return (
    <div>
      <TextField
        autoFocus
        margin="dense"
        id="challengeText"
        label={props.titleText}
        type="text"
        fullWidth
        variant="standard"
        onChange={props.onChangeTaskTitle}
        value={props.taskTitle}
      />
      {props.type === "qa" && (
        <>
          <br />
          <br />
          <TextField
            margin="dense"
            id="challengeAnswer"
            label={props.answerText}
            type="text"
            fullWidth
            variant="standard"
            onChange={props.onChangeTaskAnswer}
            value={props.taskAnswer}
          />
        </>
      )}
      <br />
      <br />
      <TextareaAutosize
        minRows={5}
        maxRows={6}
        style={{
          width: "100%",
          maxWidth: "100%",
          padding: "10px",
          outline: "none",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
        }}
        margin="dense"
        id="challengeName"
        placeholder={props.text}
        type="text"
        fullWidth
        variant="standard"
        onChange={props.onChangeTaskName}
        value={props.taskName}
      />
      <br />
      <br />
      {props.type === "multi" && (
        <>
          <MultipleChoice
            options={props.optionsArray ?? [{index: 1, value: ""}]}
            correct={props.correct}
            onChange={props.setCorrect}
            updateOption={props.updateOption}
            onAddOptionClick={props.onAddOptionClick}
            removeOption={props.removeOption}
          />
          <br />
          <br />
        </>
      )}
      {props.type !== "information" && (
        <>
        <TextField
        type="number"
        id="outlined-number"
        label="Score"
        onChange={props.onChangeTaskScore}
        placeholder="Score"
        value={props.taskScore}
        fullWidth
        variant="standard"
      />
      <br />
      <br />
      </>
    )}
      <div
        style={{
          display: "inline-flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BasicSelect
          options={props.categories}
          label={props.taskCategory ? props.taskCategory : "NONE"}
          style={{ width: "90%" }}
          onChange={(e) => props.onCategoryChange(e)}
          value={props.taskCategory ? props.taskCategory : null}
        />
        <div
          value=""
          style={{
            backgroundColor: "red",
            borderRadius: "50px",
            padding: "5px",
            textAlign: "center",
            color: "white",
            width: "40px",
            height: "40px",
            margin: "0px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={(e) => props.onCategoryChange(e)}
          className="pointer"
        >
          X
        </div>
      </div>
      <br />
      <br />
      <BasicSelect
        options={props.languages}
        label={props.lang}
        style={{ width: "90%" }}
        onChange={(e) => props.onLangChange(e)}
        value={props.lang}
      />
      <br />
      {mediaTasks.includes(props.type) && (
        <FormGroup>
          <FormControlLabel
            style={{
              justifyContent: "start",
              backgroundColor: "#f5efcd",
              borderRadius: "20px",
              width: isTabletOrMobile ? "70%" : "30%",
              margin: "10px 0px",
            }}
            control={
              <Checkbox
                checked={props.showInFeed}
                onChange={props.onChangeShowInFeed}
              />
            }
            label="Show in feed"
          />
        </FormGroup>
      )}
      {props.type !== "information" && (
          <FormGroup>
            <FormControlLabel
              style={{
                justifyContent: "start",
                backgroundColor: "#f5efcd",
                borderRadius: "20px",
                width: isTabletOrMobile ? "70%" : "30%",
                margin: "10px 0px",
              }}
              control={
                <Checkbox
                  checked={props.autoScore}
                  onChange={props.onChangeAutoScore}
                />
              }
              label="Auto Score"
            />
          </FormGroup>
        )}
          {props.customIcons && props.customIcons.length > 0 && (
            <FormControl>
              <h3 style={{ margin: "0px" }}>Custom Icon:</h3>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={props.taskIcon}
              >
                {props.customIcons.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    onChange={() => props.onCustomIconChange(item.value)}
                  />
                ))}
              </RadioGroup>
              <br />
            </FormControl>
          )}
          <MultipleFiles
            files={props.files}
            onChange={props.onUploadFile}
            taskThumbnail={props.taskThumbnail}
            onRemoveFile={props.onRemoveFile}
            displayThumbnail={true}
          />
          <h4>Embedded Media:</h4>
          {links.map((link, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#F2F2F2",
                borderRadius: "20px",
                padding: "20px",
                margin: "10px 0px",
              }}
            >
              <h4>Link {index + 1} Details:</h4>
              <TextField
                margin="dense"
                id={link.textId}
                label="Link Display Text"
                type="text"
                fullWidth
                onChange={link.onTextChange}
                value={link.textValue}
              />
              <TextField
                margin="dense"
                id={link.urlId}
                label="Link URL"
                type="text"
                fullWidth
                onChange={link.onUrlChange}
                value={link.urlValue}
              />
            </div>
          ))} 
    </div>
  );
}
