/* eslint-disable */

import { useState, useEffect } from "react";
import {
    FaCamera,
    FaVideo,
    FaQuestionCircle,
    FaList,
    FaCheckCircle,
    FaStar,
    FaUserAstronaut,
    FaUserGraduate,
    FaUserInjured,
    FaUserSecret,
    FaChevronDown,
    FaChevronUp,
    FaInfoCircle,
} from "react-icons/fa";
import TaskDialog from "../MultiLang/Dialog";
import { SERVER_URL, UPLOADS_API, GC_URL } from "../../Config";
import MultiTextField from "../MultiLang/TextField";
import MultiRadio from "../MultiLang/MultiRadio";
import { MdLocationOn } from "react-icons/md";
// import ImageCropper from "../partials/ImageCropper";
import CreateApiClient from "../../API";

const api = CreateApiClient();

export default function TaskList(props) {
    const [open, setOpen] = useState(false);
    const [taskOpen, setTaskOpen] = useState(null);
    const [submitTaskLoading, setSubmitTaskLoading] = useState(false);
    const [file, setFile] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [preview, setPreview] = useState(null);
    const [submitError, setSubmitError] = useState(null);
    const [answer, setAnswer] = useState("");
    const [submitted, setSubmitted] = useState(null);
    const [cropped, setCropped] = useState(false);
    const [cropInfo, setCropInfo] = useState({});
    const [videoError, setVideoError] = useState("");
    const [categoryOpen, setCategoryOpen] = useState(props.categoryOpenProps);

    const titleLength = props.isDesktopOrTablet ? 40 : 20;
    const titleFontSize = props.isDesktopOrTablet ? "20px" : "16px";

    const customIcons = ["location", "bonus"];

    useEffect(() => {
        const langAttr = props.lang.substr(0, 2).toLowerCase();
        const divs = document.querySelectorAll("div");

        divs.forEach((div, index) => {
            div.setAttribute("lang", langAttr);
        });
    }, []);

    const setCroppedPreview = (file) => {
        setPreview(file);
    };

    useEffect(() => {
        if (selectedFile) {
            // create the preview
            const objectUrl = window.URL.createObjectURL(selectedFile);
            setPreview(objectUrl);

            // free memory when ever this component is unmounted
            return () => window.URL.revokeObjectURL(objectUrl);
        }
    }, [selectedFile]);

    const onChangeAnswer = (e) => {
        setAnswer(e.target.value);
    };

    const handleOpen = (task) => {
        setOpen(true);
        setTaskOpen(task);
    };

    const handleClose = () => {
        setTaskOpen(null);
        setPreview(null);
        setSelectedFile("");
        setFile("");
        setSubmitError("");
        setVideoError("");
        setSubmitted(null);
        setAnswer(null);
        setCropped(false);
        setOpen(false);
    };

    const localization = [
        {
            name: "submitTask",
            Hebrew: "שלח/י",
            English: "Submit",
        },
        {
            name: "cancelSubmit",
            Hebrew: "ביטול",
            English: "Cancel",
        },
        {
            name: "photo",
            Hebrew: "משימת תמונה",
            English: "Photo Task",
        },
        {
            name: "video",
            Hebrew: "משימת וידיאו",
            English: "Video Task",
        },
        {
            name: "qa",
            Hebrew: "משימת חידה",
            English: "Q&A Task",
        },
        {
            name: "multi",
            Hebrew: "שאלה אמריקאית",
            English: "Multiple Options",
        },
        {
            name: "location",
            Hebrew: "משימת מיקום",
            English: "Location",
        },
        {
            name: "bonus",
            Hebrew: "משימת בונוס",
            English: "Bonus",
        },
        {
            name: "answerInput",
            Hebrew: "כתבו את התשובה שלכם כאן",
            English: "Enter your answer",
        },
        {
            name: "answerLabel",
            Hebrew: "התשובה שלך",
            English: "Your answer",
        },
        {
            name: "multiInput",
            Hebrew: "סמנו את התשובה הנכונה",
            English: "Check the right answer",
        },
        {
            name: "sentText",
            Hebrew: "המשימה נשלחה לבדיקה!",
            English: "Task has been submitted!",
        },
        {
            name: "wrongText",
            Hebrew: "תשובה לא נכונה!",
            English: "Wrong answer!",
        },
        {
            name: "rightText",
            Hebrew: "תשובה נכונה!",
            English: "That's right!",
        },
        {
            name: "Category A",
            Hebrew: "קטגוריה 1",
            English: "Category A",
            icon: <FaUserGraduate size={35} />,
        },
        {
            name: "Category B",
            Hebrew: "קטגוריה 2",
            English: "Category B",
            icon: <FaUserInjured size={35} />,
        },
        {
            name: "Category C",
            Hebrew: "קטגוריה 3",
            English: "Category C",
            icon: <FaUserSecret size={35} />,
        },
        {
            name: "Category D",
            Hebrew: "קטגוריה 4",
            English: "Category D",
            icon: <FaUserAstronaut size={35} />,
        },
        // {
        //   name: "answerLabel",
        //   Hebrew: "התשובה שלך",
        //   English: "Your answer",
        // },
        {
            name: "selectPhoto",
            Hebrew: "העלו תמונה",
            English: "Take a photo",
        },
        {
            name: "selectVideo",
            Hebrew: "העלו סרטון",
            English: "Take a video",
        },
    ];

    const icons = [
        {
            name: "location",
            icon: <MdLocationOn />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#4C8DF5",
        },
        {
            name: "bonus",
            icon: <FaStar />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#F6CD65",
        },
        {
            name: "photo",
            icon: <FaCamera />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#D74230",
            uploadStyle: (
                <label
                    htmlFor="media-upload"
                    className="custom-file-upload align-center bold"
                    style={{
                        backgroundColor: props.hunt.hunt_theme.header.bg,
                        color: props.hunt.hunt_theme.header.color,
                        padding: "10px 5px",
                        width: "100%",
                        // height: "50px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                >
                    <FaCamera size={20} />
                    <span style={{ fontWeight: "bold", margin: "0px 8px" }}>
                        {localization.find((s) => s.name === "selectPhoto")[props.lang]}
                    </span>
                </label>
            ),
        },
        {
            name: "video",
            icon: <FaVideo />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#A364D1",
            uploadStyle: (
                <label
                    htmlFor="media-upload"
                    className="custom-file-upload align-center bold"
                    style={{
                        backgroundColor: props.hunt.hunt_theme.header.bg,
                        color: props.hunt.hunt_theme.header.color,
                        padding: "10px 5px",
                        width: "100%",
                        // height: "50px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                >
                    <FaVideo size={30} />
                    <span style={{ fontWeight: "bold", margin: "0px 8px" }}>
                        {localization.find((s) => s.name === "selectVideo")[props.lang]}
                    </span>
                </label>
            ),
        },
        {
            name: "qa",
            icon: <FaQuestionCircle />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#01BF9D",
        },
        {
            name: "multi",
            icon: <FaList />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#FABE44",
        },
        {
            name: "information",
            icon: <FaInfoCircle />,
            color: "black",
            // color: props.hunt.hunt_theme.boxes.color,
            // color: "#4C8DF5",
        },
    ];

    const submitTask = async () => {
        setSubmitError(null);
        setSubmitTaskLoading(true);
        const formData = new FormData();
        if (taskOpen.type === "photo" || taskOpen.type === "video") {
            var video = document.getElementById("uploadedVideo");
            if (video && video.duration > 15) {
                setSubmitError("Video can't be longer than 15 seconds");
                setSubmitTaskLoading(false);

                setPreview(null);
                setSelectedFile("");
                setFile("");
                return;
            } else {
                formData.append("file", file);
            }
        } else {
            formData.append("answer", answer);
        }
        formData.append("player", props.player.name);
        formData.append("cropInfo", JSON.stringify(cropInfo));
        const url = `${SERVER_URL}/${UPLOADS_API}/submit-task?taskId=${taskOpen._id}&huntId=${props.hunt._id}`;
        if (taskOpen.type === "information" && taskOpen.submitted) {
            setSubmitted(taskOpen);
            setTimeout(() => {
                setSubmitted(null);
                setAnswer(null);
                props.getHunt(props.hunt._id, categoryOpen);
            }, 2000);
            setSubmitTaskLoading(false);
        } else {
            const data = await api.updateData("POST", url, {}, formData);
            if (data.player) {
                setSubmitted(taskOpen);
                setTimeout(() => {
                    setSubmitted(null);
                    setAnswer(null);
                    props.getHunt(props.hunt._id, categoryOpen);
                }, 2000);
            } else {
                setSubmitError(data);
            }
            setSubmitTaskLoading(false);
        }
    };

    function NewLineText(text) {
        const newText = text.split("\n").map((str, index) => (
            <p style={{ margin: "5px 0px" }} key={index}>
                {str}
            </p>
        ));

        return newText;
    }

    const renderTaskForm = (task) => {
        if (task.type === "photo" || task.type === "video") {
            return (
                <div style={{ textAlign: "center" }}>
                    <>
                        {task.links.filter((s) => s.url != "").length > 0
                            ? task.links
                                .filter((s) => s.url != "")
                                .map((item, index) => (
                                    <div key={index}>
                                        <h4>{item.text}</h4>
                                        <iframe
                                            width="350"
                                            height="250"
                                            src={item.url}
                                            title={item.text}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))
                            : task.filename &&
                            task.filename != "Thumbnail.png" && (
                                <img
                                    src={`${GC_URL}/${task.filename}`}
                                    style={{
                                        margin: "auto",
                                        width: props.isTabletOrMobile ? "100%" : "30%",
                                        // borderRadius: "10px",
                                    }}
                                />
                            )}

                        {/* {videoError && <p>{videoError}</p>} */}
                    </>
                    <div
                        className="shadowDiv"
                        style={{
                            margin: "15px 10px",
                            color: props.hunt.hunt_theme.header.color,
                            backgroundColor: props.hunt.hunt_theme.header.bg,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            textAlign: props.lang === "Hebrew" ? "right" : "left",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <h2>{task.title}</h2>
                            <div
                                style={{
                                    // margin: "20px auto",
                                    color: props.hunt.hunt_theme.boxes.color,
                                    backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                                    {task.score}
                                </span>
                            </div>
                        </div>
                        {NewLineText(task.name)}
                    </div>
                    {preview && (
                        <>
                            {task.type === "photo" ? (
                                <>
                                    {/* {!cropped ? (
                    <div
                      style={{
                        width: props.isTabletOrMobile ? "100%" : "30%",
                        borderRadius: "5px",
                        // height: "188px",
                        // background: preview && `url(${preview})`,
                        margin: "auto",
                      }}
                    >
                      <ImageCropper
                        setCropInfo={(info) => setCropInfo(info)}
                        lang={props.lang}
                        hunt={props.hunt}
                        isTabletOrMobile={props.isTabletOrMobile}
                        fileName={file.name}
                        image={preview}
                        setPreview={(file) => {
                          setCroppedPreview(file);
                          setCropped(true);
                        }}
                        setFile={(newFile) => {
                          setFile(newFile);
                          setCropped(true);
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={preview && preview}
                      style={{
                        width: props.isTabletOrMobile ? "95%" : "30%",
                        borderRadius: "5px",
                        // height: "188px",
                        background: preview && `url(${preview})`,
                        margin: "auto",
                      }}
                    />
                  )} */}

                                    <img
                                        src={preview && preview}
                                        style={{
                                            width: props.isTabletOrMobile ? "95%" : "30%",
                                            borderRadius: "5px",
                                            // height: "188px",
                                            background: preview && `url(${preview})`,
                                            margin: "auto",
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <video
                                        id="uploadedVideo"
                                        controls
                                        src={preview}
                                        width="350"
                                        autoPlay
                                        loop
                                        style={{ borderRadius: "2px", border: "3px solid" }}
                                    ></video>
                                </>
                            )}
                        </>
                    )}
                </div>
            );
        } else if (task.type === "qa") {
            return (
                <div style={{ textAlign: "center" }}>
                    <>
                        {task.links.filter((s) => s.url != "").length > 0
                            ? task.links
                                .filter((s) => s.url != "")
                                .map((item, index) => (
                                    <div key={index}>
                                        <h4>{item.text}</h4>
                                        <iframe
                                            width="350"
                                            height="250"
                                            src={item.url}
                                            title={item.text}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))
                            : task.filename &&
                            task.filename != "Thumbnail.png" && (
                                <img
                                    src={`${GC_URL}/${task.filename}`}
                                    style={{
                                        margin: "auto",
                                        width: props.isTabletOrMobile ? "100%" : "30%",
                                        // borderRadius: "10px",
                                    }}
                                />
                            )}

                        {/* {videoError && <p>{videoError}</p>} */}
                    </>
                    <div
                        className="shadowDiv"
                        style={{
                            margin: "15px 10px",
                            color: props.hunt.hunt_theme.header.color,
                            backgroundColor: props.hunt.hunt_theme.header.bg,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            textAlign: props.lang === "Hebrew" ? "right" : "left",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <h2>{task.title}</h2>
                            <div
                                style={{
                                    // margin: "20px auto",
                                    color: props.hunt.hunt_theme.boxes.color,
                                    backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                                    {task.score}
                                </span>
                            </div>
                        </div>
                        {NewLineText(task.name)}
                    </div>
                    <div style={{ padding: "0px 10px" }}>
                        <div
                            style={{
                                boxShadow: `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset`,
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "10px",
                            }}
                        >
                            <MultiTextField
                                lang={props.lang}
                                id="outlined-name"
                                label={
                                    localization.find((l) => l.name === "answerInput")[props.lang]
                                }
                                onChange={onChangeAnswer}
                                placeholder={
                                    localization.find((l) => l.name === "answerLabel")[props.lang]
                                }
                                value={answer}
                                required
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
            );
        } else if (task.type === "multi") {
            return (
                <div style={{ textAlign: "center" }}>
                    <>
                        {task.links.filter((s) => s.url != "").length > 0
                            ? task.links
                                .filter((s) => s.url != "")
                                .map((item, index) => (
                                    <div key={index}>
                                        <h4>{item.text}</h4>
                                        <iframe
                                            width="350"
                                            height="250"
                                            src={item.url}
                                            title={item.text}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))
                            : task.filename &&
                            task.filename != "Thumbnail.png" && (
                                <img
                                    src={`${GC_URL}/${task.filename}`}
                                    style={{
                                        margin: "auto",
                                        width: props.isTabletOrMobile ? "100%" : "30%",
                                        // borderRadius: "10px",
                                    }}
                                />
                            )}

                        {/* {videoError && <p>{videoError}</p>} */}
                    </>
                    <div
                        className="shadowDiv"
                        style={{
                            margin: "15px 10px",
                            color: props.hunt.hunt_theme.header.color,
                            backgroundColor: props.hunt.hunt_theme.header.bg,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            textAlign: props.lang === "Hebrew" ? "right" : "left",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <h2>{task.title}</h2>
                            <div
                                style={{
                                    // margin: "20px auto",
                                    color: props.hunt.hunt_theme.boxes.color,
                                    backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                                    {task.score}
                                </span>
                            </div>
                        </div>
                        {NewLineText(task.name)}
                    </div>
                    <div style={{ padding: "0px 10px" }}>
                        <div
                            style={{
                                backgroundColor: props.hunt.hunt_theme.boxes.color,
                                color: props.hunt.hunt_theme.boxes.bg,
                                padding: "10px",
                                borderRadius: "10px",
                                margin: "10px 0px",
                            }}
                        >
                            <h3>
                                {localization.find((l) => l.name === "multiInput")[props.lang]}
                            </h3>
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: props.lang === "Hebrew" ? "right" : "left",
                                }}
                            >
                                <MultiRadio
                                    onChange={(index) => setAnswer(index)}
                                    lang={props.lang}
                                    options={task.options}
                                    radioColor={props.hunt.hunt_theme.boxes.bg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (task.type === "information") {
            return (
                <div style={{ textAlign: "center" }}>
                    <>
                        {task.links.filter((s) => s.url != "").length > 0
                            ? task.links
                                .filter((s) => s.url != "")
                                .map((item, index) => (
                                    <div key={index}>
                                        <h4>{item.text}</h4>
                                        <iframe
                                            width="350"
                                            height="250"
                                            src={item.url}
                                            title={item.text}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))
                            : task.filename &&
                            task.filename != "Thumbnail.png" && (
                                <img
                                    src={`${GC_URL}/${task.filename}`}
                                    style={{
                                        margin: "auto",
                                        width: props.isTabletOrMobile ? "100%" : "30%",
                                        // borderRadius: "10px",
                                    }}
                                />
                            )}

                        {/* {videoError && <p>{videoError}</p>} */}
                    </>
                    <div
                        className="shadowDiv"
                        style={{
                            margin: "15px 10px",
                            color: props.hunt.hunt_theme.header.color,
                            backgroundColor: props.hunt.hunt_theme.header.bg,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            textAlign: props.lang === "Hebrew" ? "right" : "left",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <h2>{task.title}</h2>
                        </div>
                        {NewLineText(task.name)}
                    </div>
                </div>
            )
        }
    };

    const checkFileType = (taskType, fileType) => {
        if (taskType === "video") {
            return fileType === "video";
        } else if (taskType === "photo") {
            return fileType === "image";
        }
    };

    const onSelectFile = (e) => {
        setSubmitError("");
        // console.log(taskOpen);
        // console.log(e.target.files[0]);
        if (!checkFileType(taskOpen.type, e.target.files[0].type.split("/")[0])) {
            setSubmitError(`Must select a ${taskOpen.type}`);
            return;
        }

        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        // const file = e.target.files[0];
        // const reader = new FileReader();
        // reader.onload = (event) => {
        //   const image = new Image();
        //   image.onload = () => {
        //     const canvas = document.createElement("canvas");
        //     const context = canvas.getContext("2d");
        //     const maxSize = Math.min(image.width, image.height);
        //     canvas.width = maxSize;
        //     canvas.height = maxSize;
        //     context.drawImage(
        //       image,
        //       (image.width - maxSize) / 2,
        //       (image.height - maxSize) / 2,
        //       maxSize,
        //       maxSize,
        //       0,
        //       0,
        //       maxSize,
        //       maxSize
        //     );
        //     canvas.toBlob((blob) => {
        //       setFile(blob);
        //       setSelectedFile(blob);
        //       setCropped(true);
        //     }, file.type);
        //   };
        //   image.src = event.target.result;
        // };
        // reader.readAsDataURL(file);

        setFile(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
        setCropped(true);
    };

    const submitForm = (e) => {
        console.log(taskOpen);
    };

    return (
        <div style={{ display: "block", marginTop: "-20px" }}>
            {props.tasks
                .filter((t) => !t.submitted && !t.category)
                .sort((a, b) => a.score - b.score)
                .map((task, index) => (
                    <div
                        id="taskDiv"
                        key={index}
                        onClick={() => (!task.submitted || task.type === "information") && handleOpen(task)}
                        className={
                            task.submitted && task.type !== "information"
                                ? "pointer submitted"
                                : `animate pop delay-${index + 1} pointer`
                        }
                        style={{
                            borderRadius: "10px",
                            backgroundColor: props.hunt.hunt_theme.boxes.bg,
                            color: props.hunt.hunt_theme.boxes.color,
                            width: task.submitted ? "85%" : "90%",
                            margin: "5px auto",
                            // boxShadow: task.submitted
                            //   ? "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                            //   : "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                            // border: `6px solid ${props.hunt.hunt_theme.boxes.bg}`,
                            // boxShadow: `0px 0px 2px 2px ${props.hunt.hunt_theme.boxes.color} inset`,
                        }}
                    >
                        <fieldset
                            className="styledBox"
                            style={{
                                textAlign: props.lang === "Hebrew" ? "right" : "left",
                                width: "100%",
                                border: `1px solid ${props.hunt.hunt_theme.boxes.color}`,
                            }}
                        >
                            {/* <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {customIcons.includes(task.icon)
                          ? localization.find((l) => l.name === task.icon)[
                              props.lang
                            ]
                          : localization.find((l) => l.name === task.type)[
                              props.lang
                            ]}
                      </legend> */}
                            <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                                {task.title}
                            </legend>
                            {task.submitted ? (
                                <div
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        padding: "8px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FaCheckCircle />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        backgroundColor: customIcons.includes(task.icon)
                                            ? icons.find((i) => i.name === task.icon).color
                                            : icons.find((i) => i.name === task.type).color,
                                        color: "white",
                                        padding: "8px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {customIcons.includes(task.icon)
                                        ? icons.find((i) => i.name === task.icon).icon
                                        : icons.find((i) => i.name === task.type).icon}
                                </div>
                            )}
                            <div style={{ fontSize: titleFontSize }}>
                                {/* {task.title} */}
                                {task.name.substring(0, titleLength)}
                                {task.name.length > titleLength && "..."}
                            </div>
                            <div
                                style={{
                                    backgroundColor: props.hunt.hunt_theme.header.bg,
                                    color: props.hunt.hunt_theme.header.color,
                                    padding: "8px",
                                    width: "20px",
                                    borderRadius: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                {task.score}
                            </div>
                        </fieldset>
                    </div>
                ))}
            {props.categories &&
                props.categories.map((item, index) => (
                    <div key={index}>
                        <div
                            id={`tasks_${index}`}
                            className="pointer shadowDiv"
                            onClick={() => {
                                // window.location.href = `#tasks_${index}`;
                                categoryOpen === item.name
                                    ? setCategoryOpen("")
                                    : setCategoryOpen(item.name);
                            }}
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "90%",
                                borderRadius: "10px",
                                backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                color: props.hunt.hunt_theme.boxes.color,
                                margin: "5px auto",
                                padding: "1rem 0.5rem",
                                fontSize: "24px",
                            }}
                        >
                            <div style={{ width: "10%" }}></div>
                            <div style={{ width: "80%" }}>{item.name}</div>
                            {/* <div>
                {props.tasks
                  .filter((t) => t.category === item)
                  .reduce((partialSum, a) => partialSum + a.score, 0)}
              </div> */}
                            <div style={{ width: "10%" }}>
                                {categoryOpen != item.name ? (
                                    <FaChevronDown size={18} />
                                ) : (
                                    <FaChevronUp size={18} />
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: categoryOpen != item.name ? "none" : "block",
                                backgroundColor: props.hunt.hunt_theme.body.bg,
                                borderRadius: "10px",
                                padding: "0.5rem",
                                width: "90%",
                                margin: "auto",
                            }}
                        >
                            {props.tasks
                                .filter((t) => !t.submitted && t.category === item.name)
                                .map((task, index) => (
                                    <div
                                        id="taskDiv"
                                        key={index}
                                        onClick={() => !task.submitted && handleOpen(task)}
                                        className={
                                            task.submitted
                                                ? "pointer submitted"
                                                : `animate pop delay-${index + 1} pointer`
                                        }
                                        style={{
                                            borderRadius: "10px",
                                            backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                            color: props.hunt.hunt_theme.boxes.color,
                                            width: task.submitted ? "85%" : "90%",
                                            margin: "5px auto",
                                            // boxShadow: task.submitted
                                            //   ? "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                                            //   : "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                                            // border: `6px solid ${props.hunt.hunt_theme.boxes.bg}`,
                                            // boxShadow: `0px 0px 2px 2px ${props.hunt.hunt_theme.boxes.color} inset`,
                                        }}
                                    >
                                        <fieldset
                                            className="styledBox"
                                            style={{
                                                textAlign: props.lang === "Hebrew" ? "right" : "left",
                                                width: "100%",
                                                border: `1px solid ${props.hunt.hunt_theme.boxes.color}`,
                                            }}
                                        >
                                            {/* <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {customIcons.includes(task.icon)
                          ? localization.find((l) => l.name === task.icon)[
                              props.lang
                            ]
                          : localization.find((l) => l.name === task.type)[
                              props.lang
                            ]}
                      </legend> */}
                                            <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                {task.title}
                                            </legend>
                                            {task.submitted ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        padding: "8px",
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <FaCheckCircle />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        backgroundColor: customIcons.includes(task.icon)
                                                            ? icons.find((i) => i.name === task.icon).color
                                                            : icons.find((i) => i.name === task.type).color,
                                                        color: "white",
                                                        padding: "8px",
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {customIcons.includes(task.icon)
                                                        ? icons.find((i) => i.name === task.icon).icon
                                                        : icons.find((i) => i.name === task.type).icon}
                                                </div>
                                            )}
                                            <div style={{ fontSize: titleFontSize }}>
                                                {/* {task.title} */}
                                                {task.name.substring(0, titleLength)}
                                                {task.name.length > titleLength && "..."}
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: props.hunt.hunt_theme.header.bg,
                                                    color: props.hunt.hunt_theme.header.color,
                                                    padding: "8px",
                                                    width: "20px",
                                                    borderRadius: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {task.score}
                                            </div>
                                        </fieldset>
                                    </div>
                                ))}
                            {props.tasks
                                .filter((t) => t.submitted && t.category === item.name)
                                .map((task, index) => (
                                    <div
                                        id="taskDiv"
                                        key={index}
                                        onClick={() => (!task.submitted || task.type === "information") && handleOpen(task)}
                                        className={
                                            task.submitted && task.type !== "information"
                                                ? `animate pop delay-${index + 1} pointer submitted`
                                                : `pointer`
                                        }
                                        style={{
                                            borderRadius: "10px",
                                            backgroundColor: props.hunt.hunt_theme.boxes.bg,
                                            color: props.hunt.hunt_theme.boxes.color,
                                            width: task.submitted ? "85%" : "90%",
                                            margin: "5px auto",
                                            // boxShadow: task.submitted
                                            //   ? "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                                            //   : "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                                            // border: `6px solid ${props.hunt.hunt_theme.boxes.bg}`,
                                            // boxShadow: `0px 0px 2px 2px ${props.hunt.hunt_theme.boxes.color} inset`,
                                        }}
                                    >
                                        <fieldset
                                            className="styledBox"
                                            style={{
                                                textAlign: props.lang === "Hebrew" ? "right" : "left",
                                                width: "100%",
                                                border: `1px solid ${props.hunt.hunt_theme.boxes.color}`,
                                            }}
                                        >
                                            {/* <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {customIcons.includes(task.icon)
                          ? localization.find((l) => l.name === task.icon)[
                              props.lang
                            ]
                          : localization.find((l) => l.name === task.type)[
                              props.lang
                            ]}
                      </legend> */}
                                            <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                {task.title}
                                            </legend>
                                            {task.submitted ? (
                                                <div
                                                    style={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        padding: "8px",
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <FaCheckCircle />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        backgroundColor: icons.find(
                                                            (i) => i.name === task.type
                                                        ).color,
                                                        color: "white",
                                                        padding: "8px",
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {icons.find((i) => i.name === task.type).icon}
                                                </div>
                                            )}
                                            <div style={{ fontSize: titleFontSize }}>
                                                {/* {task.title} */}
                                                {task.name.substring(0, titleLength)}
                                                {task.name.length > titleLength && "..."}
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: props.hunt.hunt_theme.header.bg,
                                                    color: props.hunt.hunt_theme.header.color,
                                                    padding: "8px",
                                                    width: "20px",
                                                    borderRadius: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {task.score}
                                            </div>
                                        </fieldset>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            {props.tasks
                .filter((t) => t.submitted && !t.category)
                .map((task, index) => (
                    <div
                        id="taskDiv"
                        key={index}
                        onClick={() => (!task.submitted || task.type === "information") && handleOpen(task)}
                        className={
                            task.submitted && task.type !== "information"
                                ? `animate pop delay-${index + 1} pointer submitted`
                                : `pointer`
                        }
                        style={{
                            borderRadius: "10px",
                            backgroundColor: props.hunt.hunt_theme.boxes.bg,
                            color: props.hunt.hunt_theme.boxes.color,
                            width: task.submitted ? "85%" : "90%",
                            margin: "5px auto",
                            // boxShadow: task.submitted
                            //   ? "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                            //   : "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                            // border: `6px solid ${props.hunt.hunt_theme.boxes.bg}`,
                            // boxShadow: `0px 0px 2px 2px ${props.hunt.hunt_theme.boxes.color} inset`,
                        }}
                    >
                        <fieldset
                            className="styledBox"
                            style={{
                                textAlign: props.lang === "Hebrew" ? "right" : "left",
                                width: "100%",
                                border: `1px solid ${props.hunt.hunt_theme.boxes.color}`,
                            }}
                        >
                            {/* <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {customIcons.includes(task.icon)
                          ? localization.find((l) => l.name === task.icon)[
                              props.lang
                            ]
                          : localization.find((l) => l.name === task.type)[
                              props.lang
                            ]}
                      </legend> */}
                            <legend style={{ fontWeight: "bold", fontSize: "18px" }}>
                                {task.title}
                            </legend>
                            {task.submitted ? (
                                <div
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        padding: "8px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FaCheckCircle />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        backgroundColor: icons.find((i) => i.name === task.type)
                                            .color,
                                        color: "white",
                                        padding: "8px",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {icons.find((i) => i.name === task.type).icon}
                                </div>
                            )}
                            <div style={{ fontSize: titleFontSize }}>
                                {/* {task.title} */}
                                {task.name.substring(0, titleLength)}
                                {task.name.length > titleLength && "..."}
                            </div>
                            <div
                                style={{
                                    backgroundColor: props.hunt.hunt_theme.header.bg,
                                    color: props.hunt.hunt_theme.header.color,
                                    padding: "8px",
                                    width: "20px",
                                    borderRadius: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                {task.score}
                            </div>
                        </fieldset>
                    </div>
                ))}
            {taskOpen && (
                <TaskDialog
                    cropped={cropped}
                    component={renderTaskForm(taskOpen)}
                    // title={localization.find((l) => l.name === taskOpen.type)[props.lang]}
                    title={taskOpen.title}
                    onClick={(task) => submitTask(task)}
                    loading={submitTaskLoading}
                    hunt={props.hunt}
                    lang={props.lang}
                    item={taskOpen}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                    submitError={submitError}
                    // label={
                    //   taskOpen.type === "photo" || taskOpen.type === "video"
                    //     ? icons.find((s) => s.name === taskOpen.type).icon
                    //     : localization.find((l) => l.name === "submitTask")[props.lang]
                    // }
                    txt={localization.find((l) => l.name === "submitTask")[props.lang]}
                    cancel={
                        localization.find((l) => l.name === "cancelSubmit")[props.lang]
                    }
                    style={{
                        fontWeight: "bold",
                        backgroundColor: props.hunt.hunt_theme.header.bg,
                        color: props.hunt.hunt_theme.header.color,
                        borderColor: props.hunt.hunt_theme.header.bg,
                    }}
                    label={icons.find((s) => s.name === taskOpen.type).uploadStyle}
                    submitForm={submitForm}
                    selectedFile={selectedFile}
                    preview={preview}
                    onSelectFile={onSelectFile}
                    submitted={submitted}
                    sentText={localization.find((l) => l.name === "sentText")[props.lang]}
                    rightText={
                        localization.find((l) => l.name === "rightText")[props.lang]
                    }
                    wrongText={
                        localization.find((l) => l.name === "wrongText")[props.lang]
                    }
                    answer={answer}
                />
            )}
        </div>
    );
}
